import React, { useState} from 'react';
import '../styles/Facilities.css';
import menus from '../servicesData';
import { Helmet } from 'react-helmet';

const Facilities = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenuContent, setActiveSubMenuContent] = useState("");

 
  const handleMenuClick = (menu) => {
    setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
  };

  const handleSubMenuClick = (content) => {
    setActiveSubMenuContent(content);
    setActiveMenu(null); // Close the dropdown after a selection
  };
  

  return (
    <section className="facilities">
      <Helmet>
              {/* Basic SEO */}
              <title> Hegde financial Planners</title>
              <meta 
                name="description" 
                content="we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business." 
              />
              <meta 
                name="keywords" 
                content="SIP Investment,Financial Planning,Wealth Management,Project Funding,Estate planning,retirement planning,income protection,investment planning,fixed deposit,
                insurance,insurance planning,NRI investment,child Future planning" 
              />
      
              {/* Open Graph Meta Tags (Social Media Sharing) */}
              <meta property="og:title" content="Meet Your Real Estate Expert | Property Hub" />
              <meta 
                property="og:description" 
                content="we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business." 
              />
              <meta property="og:image" content="https://example.com/path-to-your-image.jpg" />
              <meta property="og:url" content="https://www.hegdefp.com/facilities" />
              <meta property="og:type" content="website" />
      
              {/* Twitter Card Meta Tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content="Meet Your Financial Expert | Hegde financial Planners" />
              <meta 
                name="twitter:description" 
                content="Welcome to Property Hub, where your property needs are met with care, expertise, and personalized service. From registration to loans and everything in between, we're dedicated to making your property experience effortless and rewarding." 
              />
              <meta name="twitter:image" content="https://example.com/path-to-your-image.jpg" />
            </Helmet>
      <div className='services-content'>
      <h1>Our Services</h1>
      </div>
      

      {/* Main Menu */}
      <div className="menu-container">
        {Object.keys(menus).map((menu) => (
          <div
            key={menu}
            className="menu-item"
            onClick={() => setActiveMenu(menu)}
            onMouseLeave={() => setActiveMenu(null)}
          >
            <h2 onClick={() => handleMenuClick(menu)}>{menu}</h2>
            {activeMenu === menu && (
              <ul className="dropdown-menu">
                {Object.entries(menus[menu]).map(([submenu, content]) => (
                  <li
                    key={submenu}
                    onClick={() => handleSubMenuClick(content)}
                    className="submenu-item"
                  >
                    {submenu}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>

      {/* Content Display */}
      <div className="content-display">
        {activeSubMenuContent ? (
        
          <p>{activeSubMenuContent}</p>
        ) : (
          <div>
          <p>SIP, also known as Regular Savings Plan (RSP) in some countries, allows you to invest a fixed amount
             at pre-defined frequencies in mutual funds. A bank or post office recurring deposit is the only other
              investment option that is similar to SIP. There are basically two options that an investor could take
              when they are making investments, one would be to invest lump sum into mutual funds and the other would
               be to invest using an SIP.</p><br/>
          <h3>Systematic Investment Plan Benefits</h3>
          <ul>
            <li>Get financial discipline to life</li>
            <li>Start Investing with a small amount</li>
            <li>Stay invested irrespective of market conditions</li>
            <li>Cost averaging brings down purchase cost</li>
            <li>Enhance possibilities of better returns</li>
          </ul><br/>
          
          <h3>Our Investment Offers</h3>
          <ul>
            <li>Get financial discipline to life</li>
            <li>Start Investing with a small amount</li>
            <li>Stay invested irrespective of market conditions</li>
            <li>Cost averaging brings down purchase cost</li>
            <li>Enhance possibilities of better returns</li>
          </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Facilities;
