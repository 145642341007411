// src/components/ProductsPage.js
import React, { useState,useEffect } from 'react';
import resources from '../resourcedata';
import '../styles/Resource.css';
import SIPCalculator from '../pages/SIPCalculator';
import { Helmet } from 'react-helmet';

const ResourcesPage = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenuContent, setActiveSubMenuContent] = useState("");

  const handleMenuClick = (menu, content) => {
    // Check if the menu has submenus (content is an object) or direct JSX content
    if (typeof content !== 'object' || React.isValidElement(content)) {
      // Set the content directly for menus with no submenus
      setActiveSubMenuContent(content);
      setActiveMenu(null); // Close the dropdown
    } else {
      // Toggle the dropdown menu
      setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
    }
  };

  const handleSubMenuClick = (content) => {
    setActiveSubMenuContent(content);
    setActiveMenu(null); // Close the dropdown after a selection
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <section className="resources">
       <Helmet>
        {/* Basic SEO */}
        <title> Hegde financial Planners</title>
        <meta 
          name="description" 
          content="we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business." 
        />
        <meta 
          name="keywords" 
          content="SIP Investment,Financial Planning,Wealth Management,Project Funding,Estate planning,retirement planning,income protection,investment planning,fixed deposit,
          insurance,insurance planning,NRI investment,child Future planning" 
        />

        {/* Open Graph Meta Tags (Social Media Sharing) */}
        <meta property="og:title" content="Meet Your Real Estate Expert | Property Hub" />
        <meta 
          property="og:description" 
          content="we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business." 
        />
        <meta property="og:image" content="https://example.com/path-to-your-image.jpg" />
        <meta property="og:url" content="https://www.hegdefp.com/resource" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Meet Your Financial Expert | Hegde financial Planners" />
        <meta 
          name="twitter:description" 
          content="Welcome to Property Hub, where your property needs are met with care, expertise, and personalized service. From registration to loans and everything in between, we're dedicated to making your property experience effortless and rewarding." 
        />
        <meta name="twitter:image" content="https://example.com/path-to-your-image.jpg" />
      </Helmet>
      <div className="resources-content">
        <h1>Resources</h1>
      </div>

      {/* Main Menu */}
      <div className="menu-container">
        {Object.keys(resources).map((menu) => {
          const menuContent = resources[menu];

          return (
            <div
              key={menu}
              className="menu-item1"
              onClick={() => setActiveMenu(menu)}
              onMouseLeave={() => setActiveMenu(null)}
            >
              <h2 onClick={() => handleMenuClick(menu, menuContent)}>{menu}</h2>
              {activeMenu === menu && typeof menuContent === 'object' && !React.isValidElement(menuContent) && (
                <ul className="dropdown-menu">
                  {Object.entries(menuContent).map(([submenu, content]) => (
                    <li
                      key={submenu}
                      onClick={() => handleSubMenuClick(content)}
                      className="submenu-item"
                    >
                      {submenu}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          );
        })}
      </div>

      {/* Content Display */}
      <div className="content-display1">
        {activeSubMenuContent ? (
          <div>{activeSubMenuContent}</div>
        ) : (
         <SIPCalculator/>
        )}
      </div>
    </section>
  );
};

export default ResourcesPage;
