import React,{useEffect} from 'react';
import '../styles/AboutUS.css';
import { Helmet } from "react-helmet";
 
const AboutUs = () => {

  const features = [
    {
      id: 1,
      icon: 'https://img.icons8.com/?size=100&id=82640&format=png&color=000000',
      description:
        'SIP Investment'
    },
    {
      id: 2,
      icon: 'https://img.icons8.com/?size=100&id=9zcV0gKAozhn&format=png&color=000000',
      title: 'Secure',
      description: '  Estate Planning.'
    },
    {
      id: 3,
      icon: 'https://img.icons8.com/?size=100&id=flBYBnpr91CT&format=png&color=000000',
      title: 'Unmatched Product Excellence',
      description:
        'Retirement Planning ' 
      },
    {
      id: 4,
      icon: 'https://img.icons8.com/?size=100&id=92090&format=png&color=000000',
      title: 'https://img.icons8.com/?size=100&id=92090&format=png&color=000000',
      description:
        'Income Protection'
    },
    {
      id: 5,
      icon: 'https://img.icons8.com/?size=100&id=PcUZCGU9w7fe&format=png&color=000000',
      title: 'Unmatched Product Excellence',
      description:
        ' Fixed Deposits. '
    },
    {
      id: 6,
      icon: 'https://img.icons8.com/?size=100&id=40078&format=png&color=000000',
      title: 'Unmatched Product Excellence',
      description:
        ' Investment Planning'
    },
    {
      id: 7,
      icon: 'https://img.icons8.com/?size=100&id=tnIK5k2ZyN9o&format=png&color=000000',
      title: 'Unmatched Product Excellence',
      description:
        ' Risk Management'
    },
    {
      id: 7,
      icon: 'https://img.icons8.com/?size=100&id=49065&format=png&color=000000',
      title: 'Unmatched Product Excellence',
      description:
        ' Insurance Planning'
    },
    {
      id: 7,
      icon: 'https://img.icons8.com/?size=100&id=7550&format=png&color=000000',
      title: 'Unmatched Product Excellence',
      description:
        ' Child Future Planning'
    }
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  });


  return (
    <section className="about-us">
      <Helmet>
        {/* Basic SEO */}
        <title> Hegde financial Planners</title>
        <meta 
          name="description" 
          content="we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business." 
        />
        <meta 
          name="keywords" 
          content="SIP Investment,Financial Planning,Wealth Management,Project Funding,Estate planning,retirement planning,income protection,investment planning,fixed deposit,
          insurance,insurance planning,NRI investment,child Future planning" 
        />

        {/* Open Graph Meta Tags (Social Media Sharing) */}
        <meta property="og:title" content="Meet Your Real Estate Expert | Property Hub" />
        <meta 
          property="og:description" 
          content="we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business." 
        />
        <meta property="og:image" content="https://example.com/path-to-your-image.jpg" />
        <meta property="og:url" content="https://www.hegdefp.com/about" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Meet Your Financial Expert | Hegde financial Planners" />
        <meta 
          name="twitter:description" 
          content="Welcome to Property Hub, where your property needs are met with care, expertise, and personalized service. From registration to loans and everything in between, we're dedicated to making your property experience effortless and rewarding." 
        />
        <meta name="twitter:image" content="https://example.com/path-to-your-image.jpg" />
      </Helmet>
      {/* Hero Section */}
      <div className="about-hero">
        <h1>About Us</h1>
      </div>

      {/* Content Section with Two Columns */}
      <div className="about-container">
        {/* Left Side: Description */}
        <div className="about-left">
          <h2 style={{color:'#2B4E84'}}>Why Choose Us</h2><br/>
          
          <p>
          We as a variegated mutual funds distribution service company remains fully dedicated towards evolving and keeping clients abreast about all those professional tools, which in return provides them superior and everlasting support to potentially end up recurrent as well as upcoming worries.
          On the basis of years of experience in the same field, My Mutual Funds Friend is committed towards providing comprehensive and complete planning services in order to eliminate all funds related worries from your lives.
          </p>
        </div>

        {/* Right Side: Image */}
        <div className="about-right">
          <img
            src={require("../assets/chooseus.jpg")}
            alt="Sujay Industries"
            className="about-image"
          />
        </div>
      </div>

      {/* Vision, Mission, and Quality Section */}
      <div className="about-cards">
        <div className="about-card">
          <img
            src={require("../assets/vision.avif")}
            alt="Vision"
          />
          <h2>Vision</h2><br/>
          <p>
          To provide comprehensive mutual funds distribution services to our clients, by empowering & enabling them to reach funds freedom.
          </p>
        </div>

        <div className="about-card">
          <img
           src={require("../assets/mission.jpg")}
            alt="Mission"
          />
          <h2>Mission</h2><br/>
          <p>
          To grow and protect our clients’ assets and to help them achieve their dreams and life goals.
          To become the trusted source of knowledge & leadership to our clients, our employees & to the community.
          </p>
        </div>

        <div className="about-card">
          <img
           src={require("../assets/philosophy.jpg")}
           alt="Quality"
          />
          <h2 style={{color:'#2B4E84'}}>Our Philosophy</h2><br/>
          <p>
          On behalf of our team, we seek to provide you with the returns that are aligned, competitive and reliable in nature.
          For the attainment of broad diversification and a well-disciplined approach, consult us today!
          </p>
        </div>
      </div>

     

     


<div className="quality-container">
        {/* Left side: Image */}
        <div className="quality-image">
          <img
            src={require("../assets/our-services.avif")}
             alt="Features"
          />
        </div>

        {/* Right side: Features List */}
        <div className="quality-content">
        <h2 style={{color:"#2B4E84"}}>Our Services</h2>
          <div className="quality-grid">
            
            {features.map((feature) => (
              <div key={feature.id} className="quality-card">
                <img src={feature.icon} alt={feature.title} className="quality-icon"></img>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* New Section: Image on the Left, Text on the Right */}
<div className="about-extra-section">
  {/* Left Side: Image */}
  <div className="extra-image-container">
    <img
      src={require('../assets/corebiusness.avif')}
      alt="State-of-the-Art "
      className="extra-image"
    />
  </div>

  {/* Right Side: Text Description */}
  <div className="extra-description">
    <h2>How are we different from others</h2>
    <p>Now you must be thinking what it that makes our services unforgettable and enriched all the times is! Paying 
      individual attention towards the already existing condition and later the immediate study to step you out from
       unpleasant financial condition, differentiates us from our competitors.</p><br/>
    <ul>
      <li>Locate realistic funds and personal goals</li>
      <li>Guarantees rolling your money till the period of retirement</li>
      <li>Develop realistic plans by addressing funds weaknesses</li>
      <li>Assess your current mutual funds health through best examining your assets, liabilities,
         income, insurance, taxes, investments and estate plan</li>
      <li>Put your plans into actions through monitoring its progress regularly</li>
    </ul>
  </div>
</div>



    </section>
  );
};

export default AboutUs;
