import React,{useEffect} from 'react';
import '../styles/Features.css';
import { Helmet } from 'react-helmet';

const Features = () => {
  const testimonials = [
   
    {
      id: 2,
      author: 'Akash Gupta',
      position: 'Operations Director at XYZ Ltd',
      feedback:
        '“I had started availing their services initially for Mutual Funds. However, soon they become our knowledge partners. They use technology effectively for ease of communication and achieving operational efficiency ”.',
      avatar: 'https://img.icons8.com/?size=100&id=234823&format=png&color=000000',
    },
    {
      id: 3,
      author: 'Rishab Rana',
      position: 'CEO at Tech Innovators',
      feedback:
        '"We have been working with Mr. Ramesh for last 7 years for various services. We have found their industry knowledge to be comprehensive and reliable. They have been tremendously valuable in soliciting advise on the right action whenever needed and also proactively support us in the implementation process".',
      avatar: 'https://img.icons8.com/?size=100&id=437232&format=png&color=000000',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <section className="features" id="features">
      <Helmet>
        {/* Basic SEO */}
        <title> Hegde financial Planners</title>
        <meta 
          name="description" 
          content="we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business." 
        />
        <meta 
          name="keywords" 
          content="SIP Investment,Financial Planning,Wealth Management,Project Funding,Estate planning,retirement planning,income protection,investment planning,fixed deposit,
          insurance,insurance planning,NRI investment,child Future planning" 
        />

        {/* Open Graph Meta Tags (Social Media Sharing) */}
        <meta property="og:title" content="Meet Your Real Estate Expert | Property Hub" />
        <meta 
          property="og:description" 
          content="we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business." 
        />
        <meta property="og:image" content="https://example.com/path-to-your-image.jpg" />
        <meta property="og:url" content="https://www.hegdefp.com/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Meet Your Financial Expert | Hegde financial Planners" />
        <meta 
          name="twitter:description" 
          content="Welcome to Property Hub, where your property needs are met with care, expertise, and personalized service. From registration to loans and everything in between, we're dedicated to making your property experience effortless and rewarding." 
        />
        <meta name="twitter:image" content="https://example.com/path-to-your-image.jpg" />
      </Helmet>
       <div className="features-container">
        {/* Left side: Image */}
        

        {/* Right side: Features List */}
        <div className="features-content">
          <div className="features-grid">
            <h1>
            Financial Education
            </h1>

            <p>We have taken the responsibility to educate and guide through our YouTube Channel.</p>

              <p>We will be letting you know about various aspects of Financial Planning and Wealth Management</p> 

              <p>We encourage you all to subscribe to our channel for new videos.</p>
            
             
          </div>
        </div>

        <div className="features-image">
        <iframe width="600" height="315" src="https://www.youtube.com/embed/-nJLqB-L2mU?si=sfsc5uTdmcdzn6Nc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    
  
   

        </div>
      </div>

        {/* image section */}
        <div class="image-slider-container">
          <h1>Awards & Accolades</h1>
        <div class="image-slider">
            <img src={require('../assets/awards-1.jpg')} alt=""/>
            <img src={require('../assets/awards-2.jpg')} alt=""/>
            <img src={require('../assets/awards-3.jpg')} alt=""/>
            <img src={require('../assets/awards-4.jpg')} alt=""/>
            <img src={require('../assets/awards-5.jpg')} alt=""/>
            <img src={require('../assets/awards-6.jpg')} alt=""/>
            <img src={require('../assets/awards-7.jpg')} alt=""/>
        </div>
        </div>

      <div className="testimonials-container">
       {/* Right side: Testimonial List */}
        
        <div className="testimonials-content">
          <h1 >
           Testimonials
          </h1>
          <div className="testimonials-grid">
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className="testimonial-card">
                <span class="top border"></span>
                
                <h4>{testimonial.author}</h4>
                <p className="position">{testimonial.position}</p><br/>
                <p className="feedback">"{testimonial.feedback}"</p>
                <span class="bottom border"></span>
              </div>
            ))}
          </div>
        </div>
      </div>

      
    </section>
  );
};

export default Features;
