import React,{useEffect} from 'react';
import '../styles/ContactUs.css';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="contact-wrapper">
      {/* Company Details Section */}
      <div className="company-details">
        <img className="logo1" src="./slogo.png" alt="logo" />
        <p>📍 #377/6,”SHIVAGIRI” 2nd Floor, 
          4th Main, 8th Cross. P.J.Extention. 
          Davangere-577 002 Karnataka</p>
           <p>📞 Phone: <a href="tel:9845169191" className="contact-link">+91-9845169191</a></p>
           <p>📞 Phone: <a href="tel:9902977322" className="contact-link">+91-9902977322</a></p>

            <p>📧 Email: <a href="mailto:hegde3469@gmail.com" className="contact-link">hegde3469@gmail.com</a></p>
        <p>🕒 Hours: Mon - Sat, 9-30 AM - 6 PM</p>
        <p>Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions.</p>
      </div>

      {/* Map Section */}
      <div className="contact-map">
        <div className="map-container">
          {/* Embedded Google Map */}
          <iframe
            title="Company Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d965.8605084279242!2d75.9218193757892!3d14.45928860726234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba2574be788f29%3A0x445ec4d3459ebfb2!2sWealthtransformers!5e0!3m2!1sen!2sin!4v1732248182708!5m2!1sen!2sin" 
            width="100%"
            height="500"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};


export default ContactUs;
