import React,{useEffect} from 'react';
import '../styles/Hero.css';
import { Helmet } from 'react-helmet';

const Hero = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  // Sample data for cards (You can modify these as needed)
  const cardsData = [
    {
      title: 'Financial Planning',
      description: 'Develop realistic plans by addressing funds weaknesses',
      icon:require("../assets/financepalning.png") ,
    },
    {
      title: 'Wealth Management',
      description: 'Guarantees rolling your money till the period of retirement.',
      icon:require("../assets/wealth.jpg"),
    },
    {
      title: 'Project Funding',
      description: 'Put your plans into actions through monitoring its progress regularly.',
      icon:require("../assets/project-funding.jpg"),
    },
   
  ];

  return (
    <section className="hero" id="home">
      <Helmet>
        {/* Basic SEO */}
        <title> Hegde financial Planners</title>
        <meta 
          name="description" 
          content="we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business." 
        />
        <meta 
          name="keywords" 
          content="SIP Investment,Financial Planning,Wealth Management,Project Funding,Estate planning,retirement planning,income protection,investment planning,fixed deposit,
          insurance,insurance planning,NRI investment,child Future planning" 
        />

        {/* Open Graph Meta Tags (Social Media Sharing) */}
        <meta property="og:title" content="Meet Your Real Estate Expert | Property Hub" />
        <meta 
          property="og:description" 
          content="we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business." 
        />
        <meta property="og:image" content="https://example.com/path-to-your-image.jpg" />
        <meta property="og:url" content="https://www.hegdefp.com/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Meet Your Financial Expert | Hegde financial Planners" />
        <meta 
          name="twitter:description" 
          content="Welcome to Property Hub, where your property needs are met with care, expertise, and personalized service. From registration to loans and everything in between, we're dedicated to making your property experience effortless and rewarding." 
        />
        <meta name="twitter:image" content="https://example.com/path-to-your-image.jpg" />
      </Helmet>
      {/* Hero Section */}
      <div className="hero-content">
        <h1>Hegde Financial Planners</h1>
        <p>we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business.</p>
      </div>

      <h1 className='heading'>We Offer Great
      Number of Finance Solutions</h1>

      {/* Cards Section */}
      <div className="cards-section">
        
        {cardsData.map((card, index) => (
          <div key={index} className="card">
            <img src={card.icon} alt={card.title} className="card-icon" />
            <h3>{card.title}</h3>
            <p>{card.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Hero;
